import React from 'react';

import { FlexGrid, Icons, wide } from '@wingscms/crane';
import styled from 'styled-components';

const PersonContainer = styled.a`
  display: block;
  padding: 20px;
  background-color: ${({ theme }) => theme.elementBackgroundColor};
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  margin-bottom: 30px;
  height: auto;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
  transition: all 0.2s ease-in-out;
  img {
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
    img {
      opacity: 0.8;
    }
  }
`;

const PersonImg = styled.img`
  display: block;
  width: 100%;
  margin-bottom: 10px;
`;

const PersonName = styled.h3`
  color: ${({ theme }) => theme.textColor};
  font-weight: 800;
  text-align: center;
  margin-bottom: 5px;
  padding: 0;
`;

const Wrapper = styled.div`
  ${wide}
  > div {
    margin: 0 auto;
    max-width: 1240px;
    padding: 0 20px;
  }
`;

const StyledFlexGrid = styled(FlexGrid)`
  background-color: ${({ theme }) => theme.appBackgroundColor};
  padding: 10px;
`;

const Byline = styled.div`
  font-size: 0.8em;
`;

export default ({ items }) => {
  return (
    <Wrapper>
      <div>
        <StyledFlexGrid
          divisions={4}
          margins={10}
          alignItems="stretch"
          style={{ marginLeft: '-20px', width: 'calc(100% + 40px)' }}
        >
          {items.map(({ name, imageUrl, imageAlt, byline, url }, i) => (
            <PersonContainer key={`${name}${i}`} index={i} href={url}>
              {imageUrl ? <PersonImg src={imageUrl} alt={imageAlt} /> : null}
              {name ? <PersonName>{name}</PersonName> : null}
              {byline ? <Byline>{byline}</Byline> : null}
            </PersonContainer>
          ))}
        </StyledFlexGrid>
      </div>
    </Wrapper>
  );
};
