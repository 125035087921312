import React from 'react';
import styled from 'styled-components';
import { Button } from '@wingscms/crane';
import { FormattedMessage } from 'react-intl';

const Title = styled.h2`
  color: ${({ theme }) => theme.formTextColor};
  text-transform: ${({ theme }) =>
    theme.uppercaseTitles ? 'uppercase' : 'none'};
`;

const LargeButton = styled(Button)`
  background-color: #000;
  color: #fff;
  width: 100%;
  display: block;
  text-align: center;
`;

const SmallButton = styled(Button)`
  background-color: #000;
  color: #fff;
  width: calc(50% - 10px);
  margin-right: 20px;
  margin-top: 20px;
  display: inline-block;
  text-align: center;
  &:nth-child(2n) {
    margin-right: 0;
  }
  @media screen and (max-width: 800px) {
    margin-right: 0;
    display: block;
    width: 100%;
  }
`;

const Alternative = styled.a`
  color: ${({ theme }) => theme.formTextColor};
  text-transform: ${({ theme }) =>
    theme.uppercaseTitles ? 'uppercase' : 'none'};
  text-align: center;
  display: block;
  width: 100%;
  padding: 20px 0 0 0;
  font-weight: bold;
`;

const OptionsWrapper = styled.div`
  margin-top: 40px;
`;

const scrollToSignup = () => {
  const signupEl = document.getElementById('campaign-content');
  if (!signupEl) return;
  const yCoordinate = signupEl.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -120;

  window.scrollTo({
    top: yCoordinate + yOffset,
    behavior: 'smooth'
  });
};

const scrollToTop = () => {
  const signupEl = document.getElementById('message-container');
  if (!signupEl) return;
  const yCoordinate = signupEl.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -80;

  window.scrollTo({
    top: yCoordinate + yOffset,
    behavior: 'smooth'
  });
};

const copyToClipboard = text => {
  var textarea = document.createElement('textarea');
  textarea.textContent = text;
  textarea.style.position = 'fixed';
  document.body.appendChild(textarea);
  textarea.select();
  try {
    return document.execCommand('copy');
  } catch (ex) {
    console.warn('Copy to clipboard failed.', ex);
    return false;
  } finally {
    document.body.removeChild(textarea);
  }
};

export default ({
  emailAddress,
  emailAddressee,
  emailSubject,
  twitterHandle,
  toggleDialog,
  message,
  messageType,
  messageTypeOptions,
  setMessageType
}) => {
  return messageType === 'email' ? (
    <OptionsWrapper>
      <Title>
        <FormattedMessage
          id="datacard.LetterCampaign.mailOptions.title"
          description="Mail options title"
          defaultMessage="Send a message using:"
        />
      </Title>

      <div>
        <SmallButton
          className="send-message send-email send-email-default"
          as="a"
          href={`mailto:${emailAddress}?subject=${encodeURIComponent(
            emailSubject
          )}&body=${encodeURIComponent(`${emailAddressee}\n\n${message}`)}`}
          onClick={scrollToSignup}
        >
          <FormattedMessage
            id="datacard.LetterCampaign.mailOptions.defaultMailProgram"
            description="Default mail program"
            defaultMessage="Your default mail program:"
          />
        </SmallButton>
        <SmallButton
          className="send-message send-email send-email-gmail"
          as="a"
          target="_blank"
          href={`https://mail.google.com/mail/?view=cm&fs=1&to=${emailAddress}&su=${encodeURIComponent(
            emailSubject
          )}&body=${encodeURIComponent(`${emailAddressee}\n\n${message}`)}`}
          onClick={scrollToSignup}
        >
          <FormattedMessage
            id="datacard.LetterCampaign.mailOptions.gmail"
            description="GMail"
            defaultMessage="Gmail"
          />
        </SmallButton>
        <SmallButton
          className="send-message send-email send-email-outlook"
          as="a"
          target="_blank"
          href={`https://outlook.live.com/?path=/mail/action/compose&to=${emailAddress}&subject=${encodeURIComponent(
            emailSubject
          )}&body=${encodeURIComponent(`${emailAddressee}\n\n${message}`)}`}
          onClick={scrollToSignup}
        >
          <FormattedMessage
            id="datacard.LetterCampaign.mailOptions.outlook"
            description="Outlook"
            defaultMessage="Outlook"
          />
        </SmallButton>
        <SmallButton
          className="send-message send-email send-email-copy"
          onClick={() => {
            copyToClipboard(`${emailAddressee}\n\n${message}`);
            document.getElementById('message-textarea').select();

            scrollToSignup();
            toggleDialog();
          }}
        >
          <FormattedMessage
            id="datacard.LetterCampaign.mailOptions.copy"
            description="Copy"
            defaultMessage="Copy"
          />
        </SmallButton>
        {messageTypeOptions.length > 1 ? (
          <Alternative
            href=""
            alt="Ik tweet liever"
            onClick={e => {
              e.preventDefault();
              setMessageType('twitter');
              scrollToTop();
            }}
          >
            <FormattedMessage
              id="datacard.LetterCampaign.mailOptions.ratherTweet"
              description="Tweet instead"
              defaultMessage="I'd rather tweet"
            />
          </Alternative>
        ) : null}
      </div>
    </OptionsWrapper>
  ) : (
    <OptionsWrapper>
      <LargeButton
        className="send-message send-tweet"
        as="a"
        target="_blank"
        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
          `${message}`
        )}`}
        onClick={() => scrollToSignup()}
      >
        <FormattedMessage
          id="datacard.LetterCampaign.mailOptions.tweet"
          description="Tweet"
          defaultMessage="Create Tweet"
        />
      </LargeButton>
      {messageTypeOptions.length > 1 ? (
        <Alternative
          href=""
          alt="Ik mail liever"
          onClick={e => {
            e.preventDefault();
            setMessageType('email');
            scrollToTop();
          }}
        >
          <FormattedMessage
            id="datacard.LetterCampaign.mailOptions.ratherMail"
            description="Mail instead"
            defaultMessage="I'd rather mail"
          />
        </Alternative>
      ) : null}
    </OptionsWrapper>
  );
};
