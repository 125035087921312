export default {
  formProps: {
    processSchema: schema => {
      // schema.properties.newsletter.title = 'Ik wil op de hoogte blijven';
      delete schema.properties.privacyConsent;
      delete schema.properties.terms;
      delete schema.properties.newsletter;
      schema.required = schema.required.filter(
        f => ['privacyConsent', 'terms', 'newsletter'].indexOf(f) === -1
      );
      return schema;
    },
    processSubmission: sub => ({
      ...sub,
      privacyConsent: true,
      terms: true,
      newsletter: true
    })
  }
};
