import React from 'react';
import { Campaign } from '@wingscms/react';
import campaignProps from '../../../../lib/campaignProps';

export default ({ formProps, ...props }) => (
  <Campaign
    {...props}
    formProps={{
      ...formProps,
      ...campaignProps.formProps
    }}
  />
);
