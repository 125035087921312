import React, { useState } from 'react';
import styled from 'styled-components';
import { injectIntl as withIntl, FormattedMessage } from 'react-intl';
import {
  Addressee,
  Container,
  Dialog,
  DialogCross,
  Message,
  Title,
  TypeSwitcher,
  SendOptions,
  WideWrap,
  MessageWrapper
} from './components';
import cross from './img/cross.svg';

const Text = styled.p`
  color: ${({ theme }) => theme.formTextColor};
`;

const getMessageTypeOptions = ({ emailAddress, twitterHandle }) => {
  return [
    ...(emailAddress ? ['email'] : []),
    ...(twitterHandle ? ['twitter'] : [])
  ];
};

const LetterCampaign = ({
  emailAddress,
  emailAddressee,
  emailMessage,
  emailSubject,
  twitterHandle,
  twitterMessage,
  twitterSubject,
  title,
  intro,
  ...props
}) => {
  const messageTypeOptions = getMessageTypeOptions({
    emailAddress,
    twitterHandle
  });
  const [messageType, setMessageType] = useState(messageTypeOptions[0]);
  const [messages, setMessages] = useState({
    email: emailMessage || '',
    twitter: twitterMessage || ''
  });
  const [showDialog, setShowDialog] = useState(false);
  const messageLength = messages[messageType].length;
  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };
  console.log(props);

  return (
    <React.Fragment>
      {showDialog ? (
        <Dialog>
          <DialogCross src={cross} onClick={toggleDialog} />
          <FormattedMessage
            id="datacard.LetterCampaign.dialog"
            description="Copy option dialog message"
            defaultMessage="The text of the e-mail is copied to your clipboard. You can paste the text in your email client and send the message to:"
          />{' '}
          {emailAddress}
        </Dialog>
      ) : null}
      <WideWrap>
        <Container id="message-container">
          <Title>
            {title || (
              <FormattedMessage
                id="datacard.LetterCampaign.title"
                description="Title"
                defaultMessage="Send a message"
              />
            )}
          </Title>
          <Text>
            {intro || (
              <FormattedMessage
                id="datacard.LetterCampaign.intro"
                description="Intro"
                defaultMessage="You can edit the text here before sending the message."
              />
            )}
          </Text>
          {messageTypeOptions.length > 1 ? (
            <TypeSwitcher
              current={messageType}
              onChange={setMessageType}
              options={messageTypeOptions}
            />
          ) : null}
          <MessageWrapper>
            {messageType === 'twitter' ? (
              <Addressee>{twitterHandle}</Addressee>
            ) : (
              <Addressee>{emailAddressee}</Addressee>
            )}
            <Message
              id="message-textarea"
              value={messages[messageType]}
              onChange={e =>
                setMessages({ ...messages, [messageType]: e.target.value })
              }
            />
          </MessageWrapper>
          {messageType === 'twitter' ? (
            <Text>
              {messageLength + `${twitterHandle} `.length}
              /280
            </Text>
          ) : null}
          <SendOptions
            emailAddress={emailAddress}
            emailAddressee={emailAddressee}
            emailSubject={emailSubject}
            twitterHandle={twitterHandle}
            toggleDialog={toggleDialog}
            message={messages[messageType]}
            messageType={messageType}
            messageTypeOptions={messageTypeOptions}
            setMessageType={setMessageType}
          />
        </Container>
      </WideWrap>
    </React.Fragment>
  );
};

export default withIntl(LetterCampaign);
