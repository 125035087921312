import styled from 'styled-components';
import { wide } from '@wingscms/crane';

export const WideWrap = styled.div`
  /* ${wide}; */
  /* padding: 0 20px; */
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.campaignFormBackgroundColor};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.defaultShadow};
  padding: ${({ theme }) => theme.mediumSpacing};
  margin: ${({ theme }) => theme.mediumSpacing} auto;
  @media screen and (min-width: 800px) {
    margin: ${({ theme }) => theme.largeSpacing} auto;
  }
`;
