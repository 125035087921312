import styled from 'styled-components';

export default styled.h2`
  font-size: 2em;
  color: ${({ theme }) => theme.campaignFormTextColor};
  text-transform: ${({ theme }) =>
    theme.uppercaseTitles ? 'uppercase' : 'none'};
  @media screen and (max-width: 800px) {
    font-size: 1.8rem;
  }
`;
