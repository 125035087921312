import React from 'react';
import DataCard from '../../../components/DataCard';
import { Content } from '@wingscms/react';
import campaignProps from '../../../../lib/campaignProps';

export default ({ cardProps, ...props }) => {
  return (
    <Content
      {...props}
      cards={[DataCard]}
      cardProps={{
        ...cardProps,
        CampaignCard: {
          ...cardProps.CampaignCard,
          ...campaignProps,
          formProps: {
            ...props.formProps,
            ...campaignProps.formProps,
            ...cardProps.CampaignCard.formProps
          }
        }
      }}
    />
  );
};
