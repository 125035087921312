import React from 'react';
import styled from 'styled-components';
import Textarea from 'react-textarea-autosize';
import { wide } from '@wingscms/crane';

export default styled(Textarea)`
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 0;
  resize: none;
  border: 3px solid #fff;
  border-radius: 4px;
  padding: 5px 12px;
  line-height: 1.2em;
  margin: 0;
  transition: all 0.2s ease-in-out;
  border: 3px dashed ${({ theme }) => theme.primaryColor};
  &:hover,
  &:focus {
    border: 3px dashed ${({ theme }) => theme.secondaryColor};
  }
`;

const MessageWrapperOuter = styled.div`
  ${wide};
  padding: 0 20px;
  border-radius: 4px;
  position: relative;
  font-family: 'courier';
`;

const MessageWrapperInner = styled.div`
  padding: 40px;
  background-color: #fff;
  box-shadow: ${({ theme }) => theme.defaultShadow};
  border-radius: 4px;
  max-width: 1200px;
  width: calc(100% - 40px);
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: 900px) {
    padding: 20px;
    max-width: 680px;
  }
`;

export const MessageWrapper = ({ children }) => (
  <MessageWrapperOuter>
    <MessageWrapperInner>{children}</MessageWrapperInner>
  </MessageWrapperOuter>
);

export const Addressee = styled.p`
  padding: 8px;
  margin: 0;
`;
