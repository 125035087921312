import React from 'react';
import styled from 'styled-components';
import { Icons } from '@wingscms/crane';

const Options = styled.div`
  margin-bottom: ${({ theme }) => theme.smallSpacing};
`;

const Button = styled.div`
  background-color: transparent;
  border: none;
  padding: 0;
  font-weight: bold;
  font-family: ${({ theme }) => theme.headerFontFamily};
  color: ${({ theme, current }) =>
    current ? theme.secondaryColor : theme.campaignFormTextColor};
  margin-right: ${({ theme }) => theme.smallSpacing};
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  svg {
    transition: all 0.2s ease-in-out;
    fill: ${({ theme, current }) =>
      current ? theme.secondaryColor : theme.campaignFormTextColor};
  }
  &:hover {
    color: ${({ theme, current }) =>
      !current ? theme.secondaryColor : theme.campaignFormTextColor};
    svg {
      fill: ${({ theme, current }) =>
        !current ? theme.secondaryColor : theme.campaignFormTextColor};
    }
  }
`;

const Text = styled.div`
  line-height: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  margin-top: 5px;
  width: 24px;
  height: 24px;
`;

const getOption = ({ option, current, onChange }) => {
  switch (option) {
    case 'email':
      return (
        <Button current={current === 'email'} onClick={() => onChange(option)}>
          <Text>Email</Text>
          <Icon>
            <Icons.Email />
          </Icon>
        </Button>
      );
    case 'twitter':
      return (
        <Button
          current={current === 'twitter'}
          onClick={() => onChange(option)}
        >
          <Text>Twitter</Text>
          <Icon>
            <Icons.Twitter />
          </Icon>
        </Button>
      );
    default:
      return <div />;
  }
};

export default ({ current, options, onChange }) => {
  return (
    <React.Fragment>
      <Options>
        {options.map(option => getOption({ option, current, onChange }))}
      </Options>
    </React.Fragment>
  );
};
