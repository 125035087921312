import styled from 'styled-components';

export default styled.div`
  position: fixed;
  display: block;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  max-width: 600px;
  padding: 20px;
  background-color: ${({ theme }) => theme.primaryColor};
  color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 99999999999;
  border-radius: 4px;
`;

export const DialogCross = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
