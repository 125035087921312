import React from 'react';
import { createCard } from '@wingscms/react';
import LetterCampaign from './LetterCampaign';
import PersonCollection from './PersonCollection';
import Video from './Video';

const DataCard = (options = []) => {
  const _options = {
    default: props => {
      console.log(props);
      return <div />;
    }
  };

  const addOption = (name, Comp) => {
    _options[name] = Comp;
  };

  options.forEach(option => addOption(option.name, option.comp));

  return ({ data = {}, schema }) => {
    const type = schema || data.type;
    const Comp = _options[type] ? _options[type] : _options.default;
    return <Comp {...data} />;
  };
};

export default createCard({
  name: 'DataCard',
  renderWith: DataCard([
    {
      name: 'lettercampaign',
      comp: props => {
        return <LetterCampaign {...props} />;
      }
    },
    {
      name: 'personcollection',
      comp: props => {
        return <PersonCollection {...props} />;
      }
    },
    {
      name: 'video',
      comp: props => {
        return <Video {...props} />;
      }
    }
  ])
});
